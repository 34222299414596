<template>
  <div>
    <p>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
      nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
      wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
    </p>
    <br /><br />
    <div class="space-between align-center">
      <div class="align-center flex-1">
        <div class="filter-icon">
          <img src="/img/filter.svg" alt="" />
        </div>

        <div class="search-wrappper mt-2 mb-2 mt-md-0 mb-md-0">
          <b-input-group size="sm">
            <b-form-input v-model="filter" placeholder="Search here...">
            </b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div class="p-relative">
        <div class="product-popup" v-if="showPopup">
          <div class="product-popup__item" @click="handleItemClick">
            <img src="/img/icons/ecourse.svg" alt="" />
            <span>E-course</span>
          </div>
          <div class="product-popup__item" @click="handleItemClick">
            <img src="/img/icons/tools.svg" alt="" />
            <span>Tools</span>
          </div>

          <div class="product-popup__item" @click="handleItemClick">
            <img src="/img/icons/ebook.svg" alt="" />
            <span>E-Book</span>
          </div>

          <div class="product-popup__item" @click="handleItemClick">
            <img src="/img/icons/produk-fisik.svg" alt="" />
            <span>Produk Fisik</span>
          </div>
        </div>
        <img
          src="/img/icons/product-popup-arrow.png"
          alt="arr"
          class="product-popup-arrow"
          v-if="showPopup"
        />
        <button
          type="button"
          class="btn mulai  font-bold"
          @click="buatProduk"
          @blur="handleBlur"
        >
          <fa-icon icon="plus-circle" class="mr-1" /> BUAT PRODUK
        </button>
      </div>
    </div>
    <br /><br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
        >
          <fa-icon icon="pencil-alt" class="mr-1" />Edit
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
        >
          <fa-icon icon="trash-alt" class="mr-1" /> Delete
        </b-button>

        <b-button
          size="sm"
          class="mr-3 action-button pub-unpub"
          variant="outline-secondary"
          v-if="row.item.published"
          @click="row.item.published = !row.item.published"
        >
          <fa-icon icon="minus-circle" class="mr-1" /> Unpublish
        </b-button>

        <b-button
          size="sm"
          class="mr-3 action-button pub-unpub"
          variant="outline-secondary"
          v-else
          @click="row.item.published = !row.item.published"
        >
          <fa-icon icon="circle" class="mr-1" /> Publish
        </b-button>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { products } from "../../../data/products";

export default {
  data() {
    return {
      items: products,

      fields: [
        { key: "no", label: "No" },
        {
          key: "name",
          label: "Nama Produk",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "tipe",
          label: "Tipe",
          sortable: true,
          class: "text-center"
        },
        { key: "kategori", label: "Kategori", sortable: true },
        { key: "actions", label: "Action" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      showPopup: false
    };
  },

  mounted() {
    this.totalRows = this.items.length;
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    buatProduk() {
      this.showPopup = !this.showPopup;
    },
    handleItemClick() {
      this.$router.push("/dashboard/product/add");
      console.log("Vooooooo");
    },
    handleBlur() {
      setTimeout(() => {
        this.showPopup = false;
      }, 111);
    }
  }
};
</script>

<style>
.search-wrapper {
  width: 100%;
  max-width: 222px;
}

.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}

.action-button:hover {
  background-color: #f08045;
  border: 1px solid #f08045;
}

.product-popup {
  position: absolute;
  z-index: 99;
  right: 0;
  top: -88px;
  width: 316px;
  height: 70px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #f08045;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 4px;
  display: flex;
}

.product-popup__item {
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: #494949;
  flex-direction: column;
}

.product-popup__item:hover {
  background-color: #ffe1d2;
}

.product-popup-arrow {
  position: absolute;
  z-index: 999;
  right: 99px;
  top: -19px;
  width: 14px;
}

.search-wrappper {
  min-width: 160px;
}

.pub-unpub {
  min-width: 105px;
}
</style>
