export const products = [
  {
    no: 1,
    name: "Soal Matematika Dasar SBMPTN 2011",
    tipe: "SMA",
    published: true,
    kategori: "12 Soal"
  },
  {
    no: 2,
    name: "Soal Bahasa Inggris SBMPTN 2015",
    tipe: "SBMPTN",
    published: true,
    kategori: "5 Soal"
  },
  {
    no: 3,
    name: "Soal Matematika Dasar SBMPTN 2011",
    tipe: "SMA",
    published: false,
    kategori: "12 Soal"
  },
  {
    no: 4,
    name: "Soal Bahasa Inggris SBMPTN 2015",
    tipe: "SBMPTN",
    published: true,
    kategori: "5 Soal"
  },
  {
    no: 5,
    name: "Soal Matematika Dasar SBMPTN 2011",
    tipe: "SMA",
    published: false,
    kategori: "10 Soal"
  },
  {
    no: 1,
    name: "Soal Matematika Dasar SBMPTN 2011",
    tipe: "SMA",
    published: true,
    kategori: "12 Soal"
  },
  {
    no: 2,
    name: "Soal Bahasa Inggris SBMPTN 2015",
    tipe: "SBMPTN",
    published: true,
    kategori: "5 Soal"
  },
  {
    no: 3,
    name: "Soal Matematika Dasar SBMPTN 2011",
    tipe: "SMA",
    published: true,
    kategori: "12 Soal"
  },
  {
    no: 4,
    name: "Soal Bahasa Inggris SBMPTN 2015",
    tipe: "SBMPTN",
    published: true,
    kategori: "5 Soal"
  },
  {
    no: 5,
    name: "Soal Matematika Dasar SBMPTN 2011",
    tipe: "SMA",
    published: true,
    kategori: "10 Soal"
  },
  {
    no: 1,
    name: "Soal Matematika Dasar SBMPTN 2011",
    tipe: "SMA",
    published: true,
    kategori: "12 Soal"
  },
  {
    no: 2,
    name: "Soal Bahasa Inggris SBMPTN 2015",
    tipe: "SBMPTN",
    published: true,
    kategori: "5 Soal"
  },
  {
    no: 3,
    name: "Soal Matematika Dasar SBMPTN 2011",
    tipe: "SMA",
    published: true,
    kategori: "12 Soal"
  },
  {
    no: 4,
    name: "Soal Bahasa Inggris SBMPTN 2015",
    tipe: "SBMPTN",
    published: true,
    kategori: "5 Soal"
  },
  {
    no: 5,
    name: "Soal Matematika Dasar SBMPTN 2011",
    tipe: "SMA",
    published: true,
    kategori: "10 Soal"
  }
];
